import { gsap } from 'gsap';

document.addEventListener('DOMContentLoaded', function() {
  const marqueeContainer = document.querySelector('.marquee-container');
  const marquee = document.querySelector('.marquee');
  const marqueeItems = marquee.querySelectorAll('.marquee-item');

  if (!marqueeContainer || !marquee || marqueeItems.length === 0) return;

  const speed = 120;

  const totalWidth = Array.from(marqueeItems).reduce((acc, item) => acc + item.offsetWidth, 0);

  // Clone items to create a seamless loop effect
  const clonesNeeded = Math.ceil(marqueeContainer.offsetWidth / totalWidth) + 1;
  for (let i = 0; i < clonesNeeded; i++) {
    marqueeItems.forEach(item => {
      const clone = item.cloneNode(true);
      marquee.appendChild(clone);
    });
  }

  gsap.set(marquee, { x: 0 });

  gsap.to(marquee, {
    x: `-=${totalWidth}`,
    duration: totalWidth / speed, // Duration is inversely proportional to speed
    ease: 'linear',
    repeat: -1,
    modifiers: {
      x: gsap.utils.unitize(value => parseFloat(value) % totalWidth)
    }
  });
});
